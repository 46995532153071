import Vue from 'vue'
import VueRouter from 'vue-router'

import topicRouter from './modules/topic'
import aiAsstRouter from './modules/aiAsst'
import { getIsIpBelong, getIp, viewLogReport } from "@/api/home";
import { getToken } from "@/utils/auth";

const personal = () => import('@/views/personal/personal')

let userIp

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home'),
    meta: {
      title: '首页-翼狐设计学习库'
    }
  },
  {
    path: '/routineAct/:ac_key?/',
    name: 'routineAct',
    component: () => import('@/views/routineAct'),
    meta: {
      title: '常规活动-翼狐设计学习库'
    }
  },
  {
    path: '/joinPath/:compName?/:ac_key?/:id?',
    name: 'joinPath',
    component: () => import('@/views/routineAct/joinPath'),
    meta: {
      title: '参加活动-翼狐设计学习库'
    }
  },
  {
    path: '/detail/:ac_key?/:id?',
    name: 'detail',
    component: () => import('@/views/routineAct/detail'),
    meta: {
      title: '参加活动-翼狐设计学习库'
    }
  },
  {
    path: '/workManage/:ac_key?/',
    name: 'workManage',
    component: () => import('@/views/routineAct/workManage'),
    meta: {
      title: '作品列表-翼狐设计学习库'
    }
  },
  {
    path: '/works',
    name: 'works',
    component: () => import('@/views/works'),
    meta: {
      title: '作品广场-翼狐设计学习库'
    }
  },
  {
    path: '/stuUpcms/:id?',
    name: 'stuUpcms',
    component: () => import('@/views/stuUpcms'),
    meta: {
      title: '学生作品上传-翼狐设计学习库'
    }
  },
  {
    path: '/stuWorkdtcms/:id?',
    name: 'stuWorkdtcms',
    component: () => import('@/views/stuWorkdtcms'),
    meta: {
      title: '学生作品详情-翼狐设计学习库'
    }
  },
  {
    path: '/u/:compName?/',
    name: 'personal',
    component: personal,
    meta: {
      title: '个人中心-翼狐设计学习库'
    }
  },
  {
    path: '/resource',
    name: 'resource',
    component: () => import('@/views/resource'),
    meta: {
      title: '资源下载-翼狐设计学习库'
    }
  },
  {
    path: '/linkInfo/:compName?/',
    name: 'linkInfo',
    component: () => import('@/views/linkInfo'),
    meta: {
      title: '网站信息-翼狐设计学习库'
    }
  },
  {
    path: '/charts',
    component: () => import('@/views/charts')
  },
  {
    path: '/downloadTOP',
    component: () => import('@/views/charts/downloadTOP')
  },

  // 创客贴功能页面
  {
    path: '/design/matting',
    component: () => import('@/views/design/matting')
  },
  {
    path: '/design/picture',
    component: () => import('@/views/design/picture')
  },

  // 学习综合页面
  {
    path: '/learn/:type?/',
    name: 'Learn',
    component: () => import('@/views/learn'),
    meta: {
      title: '翼狐设计学习库'
    }
  },

  // 活动列表页面
  {
    path: '/activity',
    name: 'Activity',
    component: () => import('@/views/activity'),
    meta: {
      title: '创作活动-翼狐设计学习库'
    }
  },

  { path: '*', redirect: '/', hidden: true },
  ...topicRouter,
  ...aiAsstRouter
]


const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/lib/'
})


router.beforeEach((to, from, next) => {
  if (!userIp) {
    getIp().then(res => {
      userIp = res.data
      // 在线上报
      viewLogReport({ ip: userIp }).then(res => { })
    })
  } else {
    // 在线上报
    viewLogReport({ ip: userIp }).then(res => { })
  }

  // 兼容旧地址跳转
  if (to.hash.indexOf('#') != -1) {
    next({
      path: to.hash.split('#')[1],
    })
  } else {
    let ipSkipArr = ['routineAct', 'detail']
    let ipSkip = ipSkipArr.indexOf(to.name)
    if (process.env.NODE_ENV == 'production') {
      if (ipSkip == -1) {
        // ip范围判断
        getIsIpBelong().then((res) => {
          if (res.data.is_in_ip != 1 && !getToken()) {
            location.href =
              "https://passport.yiihuu.com/login3.html?target_uri=//lib.yiihuu.com";
          }
        });
      }

    }
    next()
  }

})
export default router
