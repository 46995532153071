<template>
  <el-container class="ai_asst" id="libAiAsst">
    <el-header height="76px" class="ai_asst_head">
      <router-link to="/">
        <img
          src="@/assets/images/header/logo_ai_asst.png"
          alt=""
          class="logo"
        />
      </router-link>
      <ul class="module_box">
        <li
          class="module_item ta3"
          :class="{ active: $route.name == e.routerName }"
          v-for="(e, i) in moduleList"
          :key="i"
          @click="goModule(e.routerName)"
        >
          <div class="module_name toh">{{ e.name }}</div>
        </li>
      </ul>
      <div class="user_box" v-if="token && nick">
        <div
          v-if="aiCreation.includes($route.name)"
          @click="goModule('Record')"
          class="rec_btn ta3"
        >
          创作记录
        </div>
        <div class="login_box">
          <a
            href="https://lib.yiihuu.com/personal/personal_info.php"
            class="nick toh"
            >{{ nick }}</a
          >
          <span>&nbsp;|&nbsp;</span>
          <a href="javascript:;" @click="logout">退出</a>
        </div>
      </div>

      <div class="login_box" v-else>
        <a href="javascript:;" class="nick" @click="showLogin">登录</a>
        <span>&nbsp;|&nbsp;</span>
        <a @click="showRegister" href="javascript:;">注册</a>
      </div>
    </el-header>
    <el-main class="ai_asst_main">
      <router-view />
    </el-main>
    <!-- 登录弹出框 -->
    <loginDialog ref="loginDialog"></loginDialog>
    <!-- 注册弹出框 -->
    <registerDialog ref="registerDialog"></registerDialog>
  </el-container>
</template>

<script>
import { logout } from "@/api/home.js";
import { getToken, getNick } from "@/utils/auth.js";

export default {
  name: "AiAsst",
  components: {},
  data() {
    return {
      moduleList: [
        { name: "智能应用", routerName: "Agent" },
        { name: "AI创作", routerName: "Creation" },
        { name: "翼狐设计学习库", routerName: "home" },
      ],
      token: getToken(),
      nick: getNick(),
      aiCreation: ["Creation", "Toolkit", "Record"], // ai创作页面路由名称
    };
  },
  created() {},
  methods: {
    goModule(name) {
      this.$router.push({ name: name });
    },
    logout() {
      logout()
        .then((res) => {
          location.reload();
        })
        .catch((err) => {
          location.reload();
        });
    },
    showLogin() {
      this.$refs.loginDialog.showLoginDialog();
    },
    showRegister() {
      this.$refs.registerDialog.showRegisterDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.ai_asst {
  height: 100vh;
  .ai_asst_head {
    padding: 0 30px;
    display: flex;
    align-items: center;
    gap: 50px;
    z-index: 2;
    width: inherit;
    min-width: 1380px;
    border-bottom: 1px solid #f4f6f9;
    background: #fff;
    .logo {
      width: 226px;
      margin-bottom: 12px;
    }
    .module_box {
      display: flex;
      gap: 29px;
      flex: 1;

      .module_item {
        cursor: pointer;
        font-size: 14px;
        color: #333;
        line-height: 19px;
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          width: 0;
          height: 2px;
          background: #25c894;
          position: absolute;
          bottom: -10px;
          right: 0;
          left: auto;
          transition: 0.3s;
        }

        &:hover {
          color: #25c894;

          &::after {
            width: 100%;
            right: auto;
            left: 0;
          }
        }
      }

      .active {
        color: #25c894;

        &::after {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
    .user_box {
      display: flex;
      gap: 20px;
      align-items: center;
      .rec_btn {
        font-size: 12px;
        cursor: pointer;
        &:hover {
          color: #25c894;
        }
      }
    }
    .login_box {
      line-height: 76px;
      .nick {
        max-width: 120px;
      }
      a,
      span {
        color: #333;
        font: 12px/1.5 "Microsoft YaHei", \5b8b\4f53;
        vertical-align: middle;
      }
    }
  }
  .ai_asst_main {
    padding: 0;
  }
}
</style>
